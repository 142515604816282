
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { UserModule } from '@/modules/user/store'

import CreateDialog from '../components/create-dialog.vue'
import { OrganizationModule } from '../store'

@Component({
  components: {
    CreateDialog
  },
  name: 'Organizations'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  async mounted () {
    await new Promise(resolve => setTimeout(resolve, 100))

    const defaultOrganizationId = this.userModule.user?.defaultOrganizationId
    const defaultOrganization = this.organizationModule.organizations.find(o => o.id === defaultOrganizationId)
    const defaultOrganizationPermission = defaultOrganization ? this.organizationModule.organizationPermission(defaultOrganization) : undefined

    const firstOrganization = this.organizationModule.organizations[0]

    if (!this.userModule.user?.name || !this.userModule.user.jobRole || !this.userModule.user.referredBy) {
      this.$router.push({
        name: 'user-profile-setup'
      })
    } else if (defaultOrganization) {
      this.$router.replace({
        name: defaultOrganizationPermission === 'billing' ? 'organization-manage' : 'landscapes',
        params: {
          organizationId: defaultOrganization.id
        },
        query: this.$route.query
      })
    } else if (firstOrganization) {
      this.$router.replace({
        name: defaultOrganizationPermission === 'billing' ? 'organization-manage' : 'landscapes',
        params: {
          organizationId: firstOrganization.id
        },
        query: this.$route.query
      })
    } else {
      this.$router.push({
        name: 'landscape-setup'
      })
    }
  }
}
